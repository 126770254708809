import axios from "axios";
//import TokenService from "./token.service";

const instance = axios.create({
  baseURL: "https://api.icacdatasystemtest.com/",

  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token) {
      config.headers["Authorization"] = "Token " + token; // for Spring Boot back-end
      // config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      //Wrong password or locked account
      if (
        err.response.status === 403 &&
        originalConfig.url === "/core/jwt/authenticate/"
      ) {
        return Promise.reject(err);
      }
      // Access Token was expired
      if (err.response.status === 403 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await instance.post("/core/jwt/refresh/", {
            refresh: JSON.parse(localStorage.getItem("refreshToken")),
          });
          const { token: newToken, refresh: newRefresh } = rs.data;
          console.log(newToken, newRefresh);
          localStorage.setItem("token", JSON.stringify(newToken));
          localStorage.setItem("refreshToken", JSON.stringify(newRefresh));
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);
export default instance;
