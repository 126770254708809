/*
 * Title:				Loading.js
 * Name:				Loading Spinner Component
 * Version:				1.0
 * Modification Date:	07/10/21
 * Author:				Amanda Thompson
 * Purpose:				Indicates a page is loading/data is being processed
 */
import Box from "@mui/material/Box";
import React from "react";
// import { css } from "@emotion/react";
// import { useTheme } from "@mui/material/styles";
import Spinner from "../../assets/utils/spinner/Spinner";

// const override = css`
//   display: block;
//   margin: 0 auto;
// `;

export default function Loading({ message }) {
  // const theme = useTheme();

  // const classes = useStyles();

  return (
    <>
      <div
        style={{
          width: "500px",
          paddingTop: "20px",

          marginLeft: "50%",
          display: "flex",
        }}
      >
        <Box sx={{ display: "flex" }}>
          {/* <RingLoader
            size={60}
            color={theme.palette.icacBlue.main}
            css={override}
            loading
          /> */}
          <Spinner />
        </Box>
      </div>
      <div
        style={{
          width: "500px",
          height: "150px",
          marginLeft: "50%",
          display: "flex",
          paddingTop: "20px",
        }}
      >
        <span
          style={{
            textAlign: "center",
            fontSize: "1em",
            fontFamily: "Open Sans",
            fontWeight: 550,
          }}
        >
          {message ? message : "Loading..."}
        </span>
      </div>
    </>
  );
}
