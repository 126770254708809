import React from "react";
import Box from "@mui/material/Box";

const Spinner = ({ theme }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%", // Adjust based on your parent container height
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <img
          src={`${process.env.PUBLIC_URL}/images/globe.gif`}
          alt="Loading..."
          width="80"
          //   style={{ margin: "auto" }}
        />
      </Box>
    </div>
  );
};

export default Spinner;
