import { useEffect, useState } from "react";

const useInactivityTimer = (
  startTime,
  showTime,
  showInactivityDialog,
  setShowInactivityDialog,
  isAuthenticated
) => {
  const [timer, setTimer] = useState(startTime);

  const resetTimeout = () => {
    setTimer(startTime);
  };

  useEffect(() => {
    const handleEvent = () => {
      if (!showInactivityDialog) {
        resetTimeout();
      }
    };

    const events = ["load", "mousedown", "click", "scroll", "keypress"];

    if (isAuthenticated) {
      for (let i in events) {
        window.addEventListener(events[i], handleEvent);
      }
    }

    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], handleEvent);
      }
      // Reset the timer when the user logs out
      resetTimeout();
    };
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (timer > 0) {
      const timerId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [timer]);

  useEffect(() => {
    if (timer <= showTime && isAuthenticated) {
      setShowInactivityDialog(true);
    }
  }, [timer, isAuthenticated, setShowInactivityDialog]); // eslint-disable-line react-hooks/exhaustive-deps

  return [timer, resetTimeout];
};

export default useInactivityTimer;
