// Function to send event to Google Analytics
export const sendToGoogleAnalytics = ({ name, delta, id }) => {
  if (window.gtag) {
    window.gtag("event", name, {
      event_category: "Web Vitals",
      event_label: id,
      value: Math.round(name === "CLS" ? delta * 1000 : delta), // values must be integers
      non_interaction: true, // avoids affecting bounce rate
    });
  }
};
