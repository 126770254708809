import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "light",
    icacBlue: {
      light: "#90a2d1",
      main: "#132B73",
    },
    activeNavItem: {
      main: "#0d1e50",
    },
    navBar: {
      main: "#132B73",
    },
    rows: {
      main: "#f9f9f9",
    },

    idsContainerHeaderBarGradient: {
      // main: "linear-gradient(to bottom, white 10%, #E9E9E9 100%)",
      main: "#eaeaea",
    },
    headingTextIcon: {
      main: "black",
    },
    headerColor: {
      main: "white",
      // dark: "#132B73",
    },
    headerColorDark: {
      main: "#132B73",
    },
    primary: {
      main: "#132B73",
    },
    secondary: {
      main: "#d9534f",
    },
    success: {
      // main: "#5cb85c",
      main: "#d5d5d5",
    },
    background: {
      main: "#eeeeee",
    },
    defaultButton: {
      main: "#e9e9e9",
    },
    foreground: {
      main: "white",
    },
    text: {
      main: "black",
    },
    link: {
      main: "#3366CC",
    },
    highlight: {
      main: "#fcf8e3",
    },
    gray: {
      light: "#bfbfbf",
      dark: "#929292",
    },
    caseColors: {
      new: "#b46666",
      transferred: "#CA999D",
      reassigned: "#b48966",
      assigned: "#a4c9a4",
      closed: "#BEBEBE",
      unassigned: "#d5b295",
    },
  },

  typography: {
    fontFamily: ["Open Sans"],
  },
});

export default theme;
