import React, { useState, useContext, useEffect } from "react";
import Router from "./components/routing/Router";
import routes from "./components/routing/config";
import { DarkModeContext } from "./context/DarkModeContext";
import { AuthContext } from "./context/AuthContext";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import lightTheme from "./lightTheme";
import darkTheme from "./darkTheme";
import { ThemeProvider } from "@mui/material/styles";
import useInactivityTimer from "./components/inactivity/useAutoTimeout";
import InactivityDialog from "./components/inactivity/Inactivity";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const { darkMode } = useContext(DarkModeContext);
  const { isAuthenticated, handleLogout, userAccess } = useContext(AuthContext);
  // Inactivity Timer
  const INACTIVITY_TIME = userAccess.isAdmin ? 1800 : 1200;
  const DIALOG_TIME = 60;
  const [showInactivityDialog, setShowInactivityDialog] = useState(false);
  const [timer, resetTimeout] = useInactivityTimer(
    INACTIVITY_TIME,
    DIALOG_TIME,
    showInactivityDialog,
    setShowInactivityDialog,
    isAuthenticated
  );

  const location = useLocation();
  // console.log(isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      // Reset the inactivity timer when the user is authenticated
      resetTimeout();
      setShowInactivityDialog(false); // Add this line to reset the dialog state
    } else {
      // Remove event listeners when the user logs out
      const events = ["load", "mousedown", "click", "scroll", "keypress"];
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
      }
      setShowInactivityDialog(false);
    }

    isAuthenticated &&
      sessionStorage.setItem("lastVisitedPage", location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const lastVisitedPage = sessionStorage.getItem("lastVisitedPage");
  return (
    <>
      <ThemeProvider theme={darkMode === true ? darkTheme : lightTheme}>
        <div style={{ float: "right", width: "100px" }}>
          {/* <Tooltip title={darkMode === true ? "Light Mode" : "Dark Mode"}>
            <FormControlLabel
              className={classes.root}
              control={<Switch onChange={switchDarkMode} checked={darkMode} />}
            />
          </Tooltip> */}
          {isAuthenticated && (
            <InactivityDialog
              showInactivityDialog={showInactivityDialog}
              setShowInactivityDialog={setShowInactivityDialog}
              timer={timer}
              resetTimeout={resetTimeout}
              logout={handleLogout}
            />
          )}
        </div>
        {/* {isAuthenticated && ( */}
        <Router
          routes={routes}
          lastVisitedPage={lastVisitedPage}
          isAuthenticated={isAuthenticated}
        />
        {/* )} */}
      </ThemeProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        closeOnClick={true}
        // hideProgressBar={true}
        stacked
      />
    </>
  );
};

export default App;
