import React, { useContext } from "react";
import { Switch } from "react-router-dom";
import PropTypes from "prop-types";
import ICACRoute from "./ICACRoute";
import NotFoundRoute from "./NotFoundRoute";
import { AuthContext } from "../../context/AuthContext";

const Router = ({ routes, lastVisitedPage }) => {
  const { userAccess } = useContext(AuthContext);

  let keys = Object.keys(userAccess);
  let filtered = keys.filter(function (key) {
    return userAccess[key];
  });
  let userRoutes = routes.filter((route) =>
    filtered.some((value) => route.roles?.includes(value))
  );
  const basicRoutes = routes.map((route) =>
    !route.hasOwnProperty("roles") ? (
      <ICACRoute {...route} lastVisitedPage={lastVisitedPage} />
    ) : null
  );
  userRoutes = userRoutes.map((route) => (
    <ICACRoute {...route} lastVisitedPage={lastVisitedPage} />
  ));

  const renderedRoutes = basicRoutes.concat(userRoutes);

  return (
    <Switch>
      {renderedRoutes}
      <NotFoundRoute />
    </Switch>
  );
};

Router.propTypes = {
  routes: PropTypes.array.isRequired,
  lastVisitedPage: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default Router;
