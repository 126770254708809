import React, { createContext } from "react";
import useLocalStorage from "../hooks/useLocalStorage";

const DemoModeContext = createContext();

function DemoModeContextProvider(props) {
  const [demoMode, setDemoMode] = useLocalStorage("demo_mode", false);
  const switchDemoMode = () => {
    setDemoMode(!demoMode);
  };

  return (
    <div>
      <DemoModeContext.Provider value={{ demoMode, switchDemoMode }}>
        {props.children}
      </DemoModeContext.Provider>
    </div>
  );
}

export { DemoModeContext, DemoModeContextProvider };
