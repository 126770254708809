import { onCLS, onFID, onLCP, onTTFB, onINP } from "web-vitals/attribution";
import { sendToGoogleAnalytics } from "./sendWebVitals";

const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    onCLS(onPerfEntry);
    onFID(onPerfEntry);
    onLCP(onPerfEntry);
    onTTFB(onPerfEntry);
    onINP(onPerfEntry);
  }
};

// Report Web Vitals metrics to Google Analytics
const reportWebVitalsToGA = () => {
  reportWebVitals(sendToGoogleAnalytics);
};

export default reportWebVitalsToGA;
