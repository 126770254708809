/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
// import Button from '@mui/material/Button';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function InactivityDialog({
  showInactivityDialog,
  setShowInactivityDialog,
  timer,
  resetTimeout,
  logout,
}) {
  const handleClose = () => {
    setShowInactivityDialog(false);
    resetTimeout();
  };
  useEffect(() => {
    if (timer === 0 && showInactivityDialog) {
      logout();
      handleClose();
    }
  }, [timer, showInactivityDialog]);

  return (
    <div>
      {showInactivityDialog && (
        <Dialog
          open={showInactivityDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Logout in {timer} secs{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You will be logged out due to inactivity. Click continue to remain
              signed in.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
