import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useHistory, Route } from "react-router-dom";

const NotFoundRoute = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const history = useHistory();

  const render = () => {
    // Programmatically navigate based on the authentication status
    if (isAuthenticated) {
      history.push("/not_found");
    } else {
      history.push("/landing/login");
    }

    // Return null since you're handling navigation programmatically
    return null;
  };

  return <Route render={render} />;
};

export default NotFoundRoute;

//VERSION SIX??
//import React, { useContext, useEffect } from "react";
// import { useNavigate, Route } from "react-router-dom";
// import { AuthContext } from "../../context/AuthContext";

// const NotFoundRoute = () => {
//   const { isAuthenticated } = useContext(AuthContext);
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (isAuthenticated) {
//       navigate("/not_found");
//     } else {
//       navigate("/landing/login");
//     }
//   }, [isAuthenticated, navigate]);

//   return null; // No need to render anything, just handle navigation
// };

// export default NotFoundRoute;
