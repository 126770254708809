import React, { createContext } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { trackEvent } from "../assets/utils/analytics/Analytics";

const DarkModeContext = createContext();

function DarkModeContextProvider(props) {
  const [darkMode, setDarkMode] = useLocalStorage("dark_mode", false);
  const switchDarkMode = () => {
    setDarkMode(!darkMode);
    trackEvent("Dark Mode", "Dark Mode Switch", "Dark Mode Switch");
  };

  return (
    <div>
      <DarkModeContext.Provider value={{ darkMode, switchDarkMode }}>
        {props.children}
      </DarkModeContext.Provider>
    </div>
  );
}

export { DarkModeContext, DarkModeContextProvider };
