import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "dark",
    navBar: {
      main: "#182247",
    },
    activeNavItem: {
      main: "#20336A",
    },
    icacBlue: {
      light: "#90a2d1",
      main: "#303030",
    },
    idsContainerHeaderBarGradient: {
      // main: "linear-gradient(to bottom, #616161 10%, #343434 100%)",
      main: "#8A8686",
    },
    rows: {
      main: "#303030",
    },
    headerColor: {
      main: "#182247",
    },
    headerColorDark: {
      main: "white",
    },
    headingTextIcon: {
      main: "#F9F9F9",
    },
    primary: {
      // main: "#262626",
      main: "#F9F9F9",
    },
    secondary: {
      main: "#545552",
    },
    success: {
      // main: "#5cb85c",
      main: "#595959", //buttons
    },
    defaultButton: {
      main: "#595959",
    },
    background: {
      main: "#202020",
    },
    foreground: {
      main: "#625F5F",
    },
    text: {
      main: "white",
    },
    link: {
      main: "#B8C3FE",
    },
    highlight: {
      main: "#979365",
    },
    gray: {
      light: "#625F5F",
      dark: "#929292",
    },
    caseColors: {
      new: "#724C4C",
      transferred: "#957678",
      reassigned: "#725D4C",
      assigned: "#799879",
      closed: "#BEBEBE",
      unassigned: "#9A8370",
      other: "",
    },
  },

  typography: {
    fontFamily: ["Open Sans"],
  },
});

export default theme;
