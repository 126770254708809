import React from "react";
import { createRoot } from "react-dom/client";
import { Router } from "react-router-dom";
import Paper from "@mui/material/Paper";

import App from "./App";
import history from "./history";

import "./index.css";

import { DarkModeContextProvider } from "./context/DarkModeContext";
import { AuthContextProvider } from "./context/AuthContext";
import { DemoModeContextProvider } from "./context/DemoModeContext";
import reportWebVitalsToGA from "./assets/utils/analytics/reportWebVitals";

createRoot(document.getElementById("root")).render(
  <AuthContextProvider>
    <Router history={history}>
      <Paper>
        <DemoModeContextProvider>
          <DarkModeContextProvider>
            <App />
          </DarkModeContextProvider>
        </DemoModeContextProvider>
      </Paper>
    </Router>
  </AuthContextProvider>
);

// Start measuring performance and send data to Google Analytics
reportWebVitalsToGA();
