import React, { Suspense, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Route, useHistory } from "react-router-dom";
import Loading from "./Loading";

const ICACRoute = ({
  path,
  exact,
  redirect,
  component: Component,
  fallback,
  privateRoute,
  routes,
  lastVisitedPage,
}) => {
  const { isAuthenticated, loading } = useContext(AuthContext);
  const history = useHistory();

  let filteredRoutes = [];

  if (routes) {
    filteredRoutes = routes.map((route) => {
      if (!route.hasOwnProperty("privateRoute")) {
        route.privateRoute = privateRoute;
      }
      return route;
    });
  }

  if (loading) {
    return <Loading />;
  }

  if (redirect) {
    history.push(redirect);
    return null;
  }

  if (
    !privateRoute &&
    isAuthenticated &&
    lastVisitedPage &&
    lastVisitedPage !== "/deconfliction/results"
  ) {
    history.push(lastVisitedPage);
    return null;
  }

  if (!privateRoute && isAuthenticated) {
    history.push("/");
    return null;
  }

  if (privateRoute && !isAuthenticated) {
    history.push("/landing/login");
    return null;
  }

  return (
    <Suspense fallback={fallback}>
      <Route exact={exact} path={path}>
        <Component routes={filteredRoutes} />
      </Route>
    </Suspense>
  );
};

export default ICACRoute;
